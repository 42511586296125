/*
 * This file is part of the Sylius package.
 *
 * (c) Paweł Jędrzejewski
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import 'semantic-ui-css/components/popup';
import 'semantic-ui-css/components/rating';
import $ from 'jquery';
import 'slick-carousel';

import 'sylius/ui/app';
import 'sylius/ui/sylius-api-login';
import 'sylius/ui/sylius-api-toggle';

import './sylius-add-to-cart';
import './sylius-address-book';
import './sylius-province-field';
import './sylius-variant-images';
import './sylius-variants-prices';

function changeSlideToShow() {
  let slidesToShow = 0;
  if ($(window).width() < 992) {
    slidesToShow = 2;
  } else {
    slidesToShow = 3;
  }

  $('.slick-initialized').slick('slickSetOption', 'slidesToShow', slidesToShow);
}

$(document).ready(() => {
  changeSlideToShow();

  let max = $('.product-quantity').attr('max');
  max = parseInt(max);
  $('.product-quantity').on('change', function () {

    if($(this).val() > max){
      $(this).val(max);
    }

  });

  $('.slick-slide').each(function () {
    let div = $(this).find('div')[0];
    $(div).addClass('h-100')
  });
});

$(window).resize(() => {
  changeSlideToShow();
});
